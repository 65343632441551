import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import LoginLogoImage from '../../assets/images/LoginLogoImage.svg'
import BannerConfirmMail from '../../assets/images/BannerConfirmMail.svg'
import AbannerFinishRegister from '../../assets/images/AbannerFinishRegister.svg'

import './styles.css'

//components
import { showLoadIndicator } from '../../components/LoadIndicator'
import Input from '../../components/Input' 

//controllers
import Auth from '../../controllers/ControllerAuth'

export const Validation = () =>
{
    const navigate = useNavigate()

    useEffect(() =>
    {
        if(Auth.user.displayName && (Auth.user.email && Auth.user.emailVerified))
        {
            navigate("/")

            return
        }
    }, [navigate])

    return (
        <div className="a-page-validation">
            <div className="linha">
                <Verification />
            </div>
            <div className="background-black"></div>
        </div>
    )

    function Verification()
    {
        const [displayName, setDisplayName] = useState(""),
              [blockButton, setBlockButton] = useState(false),
              [countSendEmailTimer, setCountSendEmailTimer] = useState(0),
              [countTrySend, setCountTrySend] = useState(0)

        const verifyCooldown = useCallback(async (trySendEmail) => {
            const cacheStorageBlockSendEmail = localStorage.getItem("blockSendEmail")

            if(cacheStorageBlockSendEmail) {
                let timerBlockSendEmail = parseInt((parseInt(cacheStorageBlockSendEmail) - Date.now()) / 1000)

                if(timerBlockSendEmail > 0) {
                    const trySend = trySendEmail ? countTrySend + 1 : countTrySend

                    setBlockButton(true)
                    setCountTrySend(trySend)

                    timerBlockSendEmail += (trySend * 5)

                    if(timerBlockSendEmail > countSendEmailTimer)
                        setCountSendEmailTimer(timerBlockSendEmail)

                    initialCooldown(timerBlockSendEmail)
                }
            }
        }, [countSendEmailTimer, countTrySend])

        useEffect(() => {
            if(Auth.user.email && !Auth.user.emailVerified) {
                verifyCooldown()

                const interval = setInterval(async () => {
                    if(await Auth.user.reload() && Auth.user.emailVerified) {
                        clearTimeout(interval)
                        navigate(0)
                    }
                }, 2 * 1000)
    
                return () => clearInterval(interval)
            }
        }, [verifyCooldown])

        useEffect(() => {
            if(Auth.user.email && !Auth.user.emailVerified) {
                const interval = setInterval(async () => {
                    if(await Auth.user.reload() && Auth.user.emailVerified) {
                        clearTimeout(interval)
                        navigate(0)
                    }
                }, 2 * 1000)
    
                return () => clearInterval(interval)
            }
        }, [])

        if(Auth.user.email && !Auth.user.emailVerified)
        {
            return (
                <>
                    <div className="coluna-A">
                        <img className="LoginPageImage" src={BannerConfirmMail} alt="" />
                    </div>
                    <div className="coluna-B">
                        <div id="validation">
                            <div className="FormLogin-ConfirmMail">
                                <img className="LoginLogoImage" src={LoginLogoImage} alt="" />
                                <p>Verifique seu e-mail para melhorar a sua segurança</p>
                                <button className="btn btn-resendMail" style={{ opacity: blockButton ? 0.3 : 1 }} onClick={verificationEmail}>Reenviar o E-mail</button>
                                {
                                    countSendEmailTimer > 0 &&
                                        <p className="cooldownText">Aguarde {countSendEmailTimer} segundo{countSendEmailTimer !== 1 ? 's': ''} para reenviar o e-mail</p>
                                }
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if(!Auth.user.displayName)
        {
            return (
                <>
                    <div className="coluna-A">
                        <img className="LoginPageImageFinishRegister" src={AbannerFinishRegister} alt="" />
                    </div>
                    <div className="coluna-B">
                        <div id="login">
                            <form className="FormLogin-FinishRegister" onSubmit={saveUserData}>
                                <img className="LoginLogoImage" src={LoginLogoImage} alt="" />
                                <p>Preencha os últimos campos para finalizar o seu cadastro</p>
                                <Input type="username" placeholder={"Nome Completo"} value={displayName} onChange={e => setDisplayName(e.target.value)} style={{ marginBottom: "24px" }} />
                                <button className="btn btn-enter">Continuar</button>
                            </form>
                        </div>
                    </div>
                </>
            )
        }

        function saveUserData(e)
        {
            e.preventDefault()

            const updateData = {}
    
            if(!Auth.user.displayName)
                updateData.displayName = displayName
    
            showLoadIndicator(async () =>
            {
                if(await Auth.user.updateProfile(updateData))
                    navigate(0)
            })
        }

        function verificationEmail()
        {
            if(countSendEmailTimer <= 0) {
                showLoadIndicator(async () => {
                    setBlockButton(true)

                    if(await Auth.user.sendEmailVerification())
                        verifyCooldown(true)
                    else
                        setBlockButton(false)
                })
            }
        }

        async function initialCooldown(timerBlockSendEmail) {
            let timeCooldown = timerBlockSendEmail
    
            if(timeCooldown > 0) {
                const interval = setInterval(() => {
                    if(timeCooldown > 0) {
                        timeCooldown--
    
                        setCountSendEmailTimer(timeCooldown)
                    } else {
                        clearInterval(interval)
                        setBlockButton(false)
                    }
                }, 1000)
            }
        }
    }
}

export default Validation
