import { useState, useRef }  from 'react'
import { useNavigate } from 'react-router-dom'
import './styles.css'

//icons
import CloseIcon from '../../assets/images/close-icon-white.svg'
import RedIcon from '../../assets/images/red-close-icon.svg'
import UploadCloud from '../../assets/images/upload-cloud.svg'

//controllers
import Alert from '../../controllers/ControllerAlert'
import EBook from '../../controllers/ControllerEBook'

//services
import { BrasilApiService } from '../../services'

//components
import { showLoadIndicator } from '../../components/LoadIndicator'
import Input from '../Input'

const ModalManageEbook = ({ setIsVisible, selectedEBook, reloadEbooks }) => {
    const [isbn, setIsbn]                     = useState(selectedEBook.isbn || ''),
          [title, setTitle]                   = useState(selectedEBook.title || ''),
          [author, setAuthor]                 = useState(selectedEBook.author || ''),
          [frontCoverUrl]                     = useState(selectedEBook.frontCoverUrl),
          [frontCoverFile, setFrontCoverFile] = useState({}),
          [epubUrl]                           = useState(selectedEBook.epubUrl),
          [epubFile, setEpubFile]             = useState({}),
          [actionType]                        = useState(!!Object.keys(selectedEBook)?.length ? "edit" : "register"),
          selectFileCoverRef                  = useRef(null),
          selectFileEpubRef                   = useRef(null),
          navigate                            = useNavigate()

    const openSelectFileCover = () => {
        selectFileCoverRef.current.click()
    }

    const onChangeFileCover = e => {
        const [selectedFile] = e.target.files

        if(selectedFile?.type === "image/jpeg" || selectedFile?.type === "image/png") {
            selectedFile.url = URL.createObjectURL(selectedFile)

            setFrontCoverFile(selectedFile)
        } else {
            if(selectedFile?.type)
                Alert.error({ code: "file/invalid-file-type" })
            
            removeSelectedFileCover()
        }
    }

    const openSelectFileEpub = () => {
        selectFileEpubRef.current.click()
    }

    const onChangeFileEpub = e => {
        const [selectedFileEpub] = e.target.files
        
        if(selectedFileEpub?.type === "application/epub+zip") {
            selectedFileEpub.url = URL.createObjectURL(selectedFileEpub)

            setEpubFile(selectedFileEpub)
        } else {
            if(selectedFileEpub?.type)
                Alert.error({ code: "file/invalid-file-type" })

            removeSelectedFileEpub()
        }
    }

    const removeSelectedFileEpub = () => {
        selectFileEpubRef.current.value = ""

        setEpubFile({})
    }

    const removeSelectedFileCover = () => {
        selectFileCoverRef.current.value = ""

        setFrontCoverFile({})
    }

    const createEbook = () => {
        showLoadIndicator(async () => {
            if(await EBook.createEbook(isbn, title, author, frontCoverFile, epubFile)) {
                if(reloadEbooks)
                    await reloadEbooks?.()
                else
                    navigate(0)

                setIsVisible(false)
            }
        })
    }

    const updateEbook = () => {
        showLoadIndicator(async () => {
            if(await EBook.updateEbook(isbn, title, author, frontCoverFile, epubFile)) {
                if(reloadEbooks)
                    await reloadEbooks?.()
                else
                    navigate(0)

                setIsVisible(false)
            }
        })
    }

    const getBrasilApiData = async () => {
        const data = await new BrasilApiService().getIsbn(isbn)

        if(data) {
            if((title && data.title !== title) || (author && data.authors[0] !== author)) {
                const message = `
                    Gostaria de mudar 
                    ${data.title !== title ? "o título de \"" + title + "\" para \"" + data.title + "\" " : ""}
                    ${data.title !== title && data.authors[0] !== author ? "e " : ""}
                    ${data.authors[0] !== author ? "o autor de \"" + author + "\" para \"" + data.authors[0] + "\"" : ""}
                `

                Alert.alert("Sugestão", message, () => {
                    setTitle(data.title)
                    setAuthor(data.authors[0])
                })
            } else {
                setTitle(data.title)
                setAuthor(data.authors[0])
            }
        }
    }
    
    return (
        <>    
            <div className="modal-manage-fade" onClick={() => setIsVisible(false)}></div>
            <div className="modal-manage">
                <img className="close-modal-icon" alt="Close-icon" src={CloseIcon} onClick={() => setIsVisible(false)} />
                <div className="modal-manage-content">
                    <div className="a-preview">
                        {
                            (frontCoverFile?.url || frontCoverUrl) &&
                                <img className="ebook-image-modal" src={frontCoverFile?.url || frontCoverUrl} alt="" />
                        }
                        <div className={`selected-file-footer ${(!frontCoverFile?.url && !frontCoverUrl) ? "not-selected-file" : ""}`} onClick={openSelectFileCover}>
                            {(!frontCoverFile?.url && !frontCoverUrl) ? <img src={UploadCloud} alt=""></img> : ""}
                            <p className="change-ebook-image-text">
                                {(frontCoverFile?.url || frontCoverUrl) ? "Alterar Capa" : "Selecione uma capa"}
                            </p>
                            <p className="ebook-type-image-text">(PNG/JPEG)</p>
                            <input
                                style={{ display: "none" }}
                                type="file"
                                ref={selectFileCoverRef}
                                onChange={e => {
                                    e.preventDefault()

                                    onChangeFileCover(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="a-form">
                        <div className="a-inputs">
                            <p>ISBN</p>
                            <Input
                                type="text"
                                disabled={actionType === "edit"}
                                showIcon={false}
                                value={isbn}
                                onChange={e => setIsbn(actionType === "register" ? e.target.value.replace(/[^0-9]+/g, '') : isbn)}
                                style={{ marginBottom: "24px" }}
                                onBlur={getBrasilApiData}
                            />
                            <p>Título</p>
                            <Input
                                type="text"
                                showIcon={false}
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                style={{ marginBottom: "24px" }}
                            />
                            <p>Autor</p>
                            <Input
                                type="text"
                                showIcon={false}
                                value={author}
                                onChange={e => setAuthor(e.target.value)}
                                style={{ marginBottom: "24px" }}
                            />
                        </div>
                        <button className="select-epub" onClick={openSelectFileEpub}>
                            {epubFile?.url || epubUrl ? "Alterar" : "Selecionar"} (EPUB)
                        </button>
                        <input 
                            style={{ display: "none" }}
                            type="file"
                            ref={selectFileEpubRef} 
                            onChange={e => {
                                e.preventDefault()

                                onChangeFileEpub(e)
                            }}
                        />
                        {
                            (epubFile?.name || epubUrl) &&  (
                                <div className="a-buttons">
                                    <p className="selected-file">{epubFile.name || "ebook.epub"}</p>
                                    {
                                        epubFile.name && (
                                            <img className="close-icon" src={RedIcon} alt="" onClick={removeSelectedFileEpub} />
                                        )
                                    }
                                </div>
                            )
                        }
                        <button className="register-form" onClick={e => {
                            e.preventDefault()
                            
                            if(actionType === "register")
                                createEbook()
                            else if(actionType === "edit")
                                updateEbook()
                        }}>
                            {actionType === "register" ? "Cadastrar" : "Salvar"}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalManageEbook

