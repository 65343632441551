import EBook from '../controllers/ControllerEBook'
import Alert from '../controllers/ControllerAlert'

import { showLoadIndicator } from '../components/LoadIndicator'

export const verifyRescueEbooks = async (success, err) => {
    let rescueEbooksList = sessionStorage.getItem("rescueEbooks")

    rescueEbooksList = JSON.parse(rescueEbooksList)

    if(rescueEbooksList && rescueEbooksList.length > 0) {
        for(let ebook of rescueEbooksList) {
            try {
                const eBookData = await EBook.getEbookDataByIsbn(ebook.isbn)

                await new Promise((resolve, reject) => {
                    Alert.alert("Resgatar livro", `Você gostaria de resgatar o livro ${eBookData.title}?`, async () => {
                        try {
                            await new Promise((resolve, reject) => {
                                showLoadIndicator(async () => {
                                    try {
                                        if(await EBook.validateKeyEBook(ebook.isbn, ebook.code.toUpperCase())) {
                                            Alert.success("Sucesso", `${eBookData.title} foi adicionado a sua biblioteca!`)
                                            success?.()
                                            resolve()
                                        } else {
                                            reject(new Error("ebook not rescued"))
                                        }
                                    } catch(error) {
                                        reject(error)
                                    }
                                })
                            })

                            resolve()
                        } catch(error) {
                            reject(error)
                        }
                    }, () => resolve())
                })
            } catch(error) {
                console.error(error)
                err?.()
            }
        }

        sessionStorage.removeItem("rescueEbooks")
    }
}