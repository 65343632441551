export const getNavigatorInfo = () => {
    if(navigator?.userAgentData) {
        return {
            platform: navigator?.userAgentData?.platform,
            brand: navigator?.userAgentData?.brands?.[0].brand
        }
    }

    const listBrands = {
        "Edg": "Microsoft Edge",
        "Chrome": "Google Chrome",
        "Safari": "Safari",
        "Opera": "Opera",
        "Firefox": "Mozilla Firefox",
        "MSIE": "Microsoft Internet Explorer"
    }

    const listPlatforms = {
        "Windows": "Windows",
        "Macintosh": "MacOS",
        "Android": "Android"
    }

    const brand = Object.keys(listBrands).find(data => navigator.userAgent.indexOf(data) !== -1)
    const platform = Object.keys(listPlatforms).find(data => navigator.userAgent.indexOf(data) !== -1)

    return {
        brand: listBrands[brand],
        platform: listPlatforms[platform]
    }
}