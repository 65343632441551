import { useEffect, useState } from 'react'
import { CircularProgress } from 'react-loading-indicators'

let updateLoadIndicatorState

const LoadIndicator = () =>
{
    const [show, setShow] = useState(false)

    useEffect(() =>
    {
        updateLoadIndicatorState = setShow

        return () => updateLoadIndicatorState = null
    }, [setShow])

    return (
        <>
        {
            show &&
            (
                <div style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#000",
                    opacity: 0.7,
                    zIndex: 9999,
                    top: 0,
                    display: "flex"
                }}>
                    <CircularProgress color="#fff" size="large" text="" textColor="" />
                </div>
            )
        }
        </>
    )
}

const showLoadIndicator = callback =>
{
    if(typeof callback == "function")
    {
        updateLoadIndicatorState(true)

        callback()
            .finally(() => updateLoadIndicatorState(false))
    }
    else if(typeof callback == "boolean")
        updateLoadIndicatorState(!!callback)
}

export default LoadIndicator
export { showLoadIndicator }