import { useEffect, useState } from 'react'

import './styles.css'

let updateTitle,
    updateMessage,
    updateButtons,
    updateOptions

const AlertIndicator = () =>
{
    const [title, setTitle]     = useState(''),
          [message, setMessage] = useState(''),
          [buttons, setButtons] = useState([]),
          [options, setOptions] = useState({})

    useEffect(() =>
    {
        updateTitle = setTitle
        updateMessage = setMessage
        updateButtons = setButtons
        updateOptions = setOptions

        return () => updateTitle = updateMessage = updateButtons = updateOptions = null
    }, [setTitle, setMessage, setButtons, setOptions])

    return (
        <div id="alert" onClick={(e) =>
        {
            if(e.target.id === "alert" && options?.cancelable) {
                hide()
                options?.cancelAction?.()
            }
        }}>
            <div className="a-content">
                <div className="a-title">
                    <h1>{title}</h1>
                </div>
                <div className="a-message">
                    <p>{message}</p>
                </div>
                <div className="a-buttons">
                    {
                        buttons &&
                        <>
                            {
                                buttons.map((button, index) =>
                                {
                                    const click = async () =>
                                    {
                                        if(button.onPress && typeof button.onPress === 'function')
                                            await button.onPress()
                                        
                                        hide()
                                    }

                                    return (
                                        <div className="alert-button" onClick={click} key={index}>
                                            <p>{button.text}</p>
                                        </div>
                                    )
                                })
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

const show = () =>
{
    const alertElement = document.querySelector("#alert")

    alertElement.style.display = "flex"
    alertElement.style.opacity = 1
}

const hide = () =>
{
    const alertElement = document.querySelector("#alert")

    alertElement.style.display = "none"

    setTimeout(() =>
    {
        alertElement.style.opacity = 1
    }, 300)
}

const alert = (title, message, actionButtons, options) =>
{
    updateTitle(title)
    updateMessage(message)
    updateOptions(options)

    if(typeof actionButtons == "object" && actionButtons.length > 0)
        updateButtons(actionButtons)

    show()
}

export default AlertIndicator
export { alert }