import { useState, useEffect, Children } from 'react'

//controllers
import Auth from '../../controllers/ControllerAuth'

export default function IsLoadingUserData({ children })
{
    const [userIsLoad, setUserIsLoad] = useState(false)

    useEffect(() =>
    {
        async function authFirstState()
        {
            await Auth.isFirstStateLoaded()
            await Auth.isLoadUser()

            setUserIsLoad(true)
        }

        authFirstState()
    }, [])

    return (
        <>
            {
                userIsLoad &&
                    Children.map(children, child => child)
            }
        </>
    )
}