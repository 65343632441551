import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BannerRegister from '../../assets/images/BannerRegister.svg'
import LoginLogoImage from '../../assets/images/LoginLogoImage.svg'

import './styles.css'

//components
import { RulesPassword } from '../../components/RulesPassword'
import { showLoadIndicator } from '../../components/LoadIndicator'
import Input from '../../components/Input'

//controllers
import Auth from '../../controllers/ControllerAuth'

export const Register = () =>
{
    const [email, setEmail]                     = useState(""),
          [password, setPassword]               = useState(""),
          [confirmpassword, setConfirmPassword] = useState(""),
          navigate                              = useNavigate()

    const registerAction = e =>
    {
        e.preventDefault()

        showLoadIndicator(async () =>
        {
            if(await Auth.createUserWithEmailAndPassword(email, password, confirmpassword))
                Auth.signInRedirect(navigate)
        })
    }

    return (
        <div className="a-page-register">
            <div className="linha">
                <div className="coluna-A">
                    <img className="RegisterPageImage" src={BannerRegister} alt="" />
                </div>
                <div className="coluna-B">
                    <div id="register">
                        <form className="FormLogin" onSubmit={registerAction}>
                            <img className="logoImage" src={LoginLogoImage} alt="" />
                            <RulesPassword value={password} />
                            <Input type="email" placeholder={"E-mail"} value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: "24px", width:"100%"}} />
                            <Input type="password" placeholder={"Senha"} value={password} onChange={e => setPassword(e.target.value)} style={{ marginBottom: "24px" }} />
                            <Input type="password" placeholder={"Confirmar senha"} value={confirmpassword} onChange={e => setConfirmPassword(e.target.value)} style={{ marginBottom: "24px" }} />
                            <div className="terms-of-use">
                                <p>
                                    <p>Ao criar uma conta, você concorda com nossos </p>
                                    <a href="./privacypolicy" target="_blank" rel="noreferrer">
                                        Termos de Uso
                                    </a>
                                    <p>e</p> 
                                     <a href="./privacypolicy" target="_blank" rel="noreferrer">
                                        Política de Privacidade
                                    </a>
                                </p>
                            </div>
                            <button className="btn btn-enter-register">Registrar</button>
                            <div className='Registrar'>
                                <p>Já possui conta?<span onClick={() => navigate("/login")} className="blueColor">Entrar</span></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="background-black"></div>
        </div>
    )
}

export default Register

