import Firebase from './ControllerFirebase'
import Alert from './ControllerAlert'

//utils
import { validatePassword } from '../utils/validates'

export default class UserBase
{
    /**
     * Classe abstrata, não pode ser instanciada diretamente.
     * 
     * @param {String} userId - Id do usuário.
     * 
     * @returns {UserBase}
     */

    constructor(userId)
    {
        if(this.constructor === UserBase)
            throw new Error("Você não deveria instancia um objeto do tipo Identifier diretamente, pois essa é uma classe abstrata")

        this._uid = userId || null

        this._isLoadData = false
        this._data       =
        {
            displayName: null,
            photoURL: null
        }

        this._updatePhoneAuthSnapshot = null
    }

    /**
     * Retorna a instância do usuário do firebase sempre atualizada.
     * 
     * @returns {Object}
     */

    get _sessionData()
    {
        return this._uid ? {} : Firebase.user
    }

    /**
     * Retorna o id do usuário.
     * 
     * @returns {String}
     */

    get uid()
    {
        return this._uid || this._sessionData.uid
    }

    /**
     * Retorna o nome do usuário.
     * 
     * @returns {String}
     */

    get displayName()
    {
        return this._data.displayName
    }

    /**
     * Retorna a url da foto de perfil do usuário.
     * 
     * @returns {String}
     */

    get photoURL()
    {
        return this._data.photoURL
    }

    /**
     * Retorna o e-mail do usuário.
     * 
     * @returns {String}
     */

    get email()
    {
        return this._sessionData.email
    }

    /**
     * Retorna se o usuário verificou o email ou não.
     * 
     * @returns {Boolean}
     */

    get emailVerified()
    {
        return this._sessionData.emailVerified
    }

    /**
     * Retorna o número de telefone do usuário.
     * 
     * @returns {String}
     */

    get phoneNumber()
    {
        return this._sessionData.phoneNumber
    }

    /**
     * Retorna se os dados do usuário foram carregados ou não.
     * 
     * @returns {Boolean}
     */

    get isLoadData()
    {
        return this._isLoadData
    }

    /**
     * Retorna a referência do banco de dados do usuário do Firebase.
     * 
     * @returns {FirebaseFirestoreTypes.DocumentData}
     */

    get ref()
    {
        return Firebase.firestore().doc(`user/${this.uid}`)
    }

    /**
     * Carrega ou cria os dados do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.getOrSetData() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    async getOrSetData()
    {
        try
        {
            if(this._isLoadData)
                throw new Error("Data is already loaded")

            let data = await Firebase.firestore().collection("user").doc(this.uid).get()

            data = data.exists ? data.data() : null

            if(data && Object.keys(this._data).some(userData => Object.keys(data).includes(userData)))
                this._data = data
            else
            {
                if(!this._sessionData)
                    throw { code: "auth/session-not-found" }
                
                if(this._sessionData.displayName)
                    this._data.displayName = this._sessionData.displayName

                if(this._sessionData.photoURL)
                    this._data.photoURL = this._sessionData.photoURL
                
                await Firebase.firestore().collection("user").doc(this.uid).set(this._data)
            }

            this._isLoadData = true

            return true
        }
        catch(error)
        {
            Alert.error(error)
            // Alert.error({ message: "Falha ao criar / buscar usuário" })

            return false
        }
    }

    /**
     * Envia um e-mail de verificação para o usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.sendEmailVerification() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    async sendEmailVerification()
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            const actionCodeSettings = {
                url: `${process.env.REACT_APP_REDIRECT_URL}/?email=${this.email}`,
                iOS: {
                    bundleId: process.env.REACT_APP_IOS_BUNDLE_IDENTIFIER
                },
                android: {
                    packageName: process.env.REACT_APP_ANDROID_PACKAGE,
                    installApp: true,
                    minimumVersion: "12"
                },
                handleCodeInApp: true,
                dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK
            }

            localStorage.setItem("blockSendEmail", (Date.now() + (1000 * 30)).toString())
            await this._sessionData.sendEmailVerification(actionCodeSettings)

            return true
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Força o recarregamento da sessão do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.reload() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    async reload()
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            await this._sessionData.reload()
            
            return true
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Atualiza os dados do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.updateProfile({ displayName: "Jorge Matheus", photoUrl: "http://example.com/photo.png"}) // true ou false
     * ```
     * 
     * @param {Object} data - Objeto de dados a serem atualizados, Obs.: Segue o atributo "this._data" como padrão para os dados.
     * 
     * @returns {Promise<boolean>}
     */

    async updateProfile(data)
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            if(!data || Object.values(data).length <= 0)
                throw new Error("Variable 'data' cannot be null")

            const hasDataEmptyInProfileData = !!Object.values(data).some(field => field === null || field === "")
            
            if(hasDataEmptyInProfileData)
                throw { code: "generic/fill-in-all-fields" }
            else
            {
                await Firebase.firestore().collection("user").doc(this.uid).update(data)

                Object.assign(this._data, data)

                return true
            }
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Atualiza o número de telefone do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.updatePhoneNumber("+5521987654321") // true ou false
     * ```
     * 
     * @param {String} phoneNumber - Número de telefone.
     * 
     * @returns {Promise<Boolean>}
     */

    async updatePhoneNumber(phoneNumber)
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            if(!phoneNumber)
                throw { code: "auth/insert-phone-number" }

            if(typeof phoneNumber !== "string")
                throw new Error("The 'phoneNumber' variable must be a String")

            return await new Promise((resolve, reject) =>
            {
                Firebase
                    .auth()
                    .verifyPhoneNumber(phoneNumber)
                    .on('state_changed', phoneAuthSnapshot =>
                    {
                        if(phoneAuthSnapshot.state === Firebase.auth.PhoneAuthState.CODE_SENT)
                        {
                            this._updatePhoneAuthSnapshot = phoneAuthSnapshot
                            
                            resolve(true)
                        }
                    }, error => reject(error))
            })
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Confirma o código de verificação enviado por sms para o usuário atualizar o número de telefone.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.confirmCodeToUpdatePhoneNumber("123456") // true ou false
     * ```
     * 
     * @param {Number} code - Código enviado por sms para o usuário.
     * 
     * @returns {Promise<Boolean>}
     */

    async confirmCodeToUpdatePhoneNumber(code)
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            if(!code)
                throw new Error("Code is required")

            if(!this._updatePhoneAuthSnapshot)
                throw { code: "auth/there-is-no-pending-confirmation" }

            const credential = Firebase.auth.PhoneAuthProvider.credential(this._updatePhoneAuthSnapshot.verificationId, code)

            try
            {
                await this._sessionData.updatePhoneNumber(credential)

                return true
            }
            finally
            {
                this._updatePhoneAuthSnapshot = null
            }
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Atualizar o e-mail do usuário
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.updateEmail("teste@email.com.br") // true ou false
     * ```
     * 
     * @param {String} email - Novo e-mail que gostaria de implementar.
     * 
     * @returns {Promise<Boolean>}
     */    

    async updateEmail(email)
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            await this._sessionData.updateEmail(email)
            await this.reload()

            return true
        }
        catch(error)
        {
            Alert.error(error)
            
            return false
        }
    }

    /**
     * Redefinição da senha do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.redefinePassword("987654321", "123456789", "123456789") // true ou false
     * ```
     * 
     * @param {String} password           - Senha antiga.
     * @param {String} newPassword        - Nova senha.
     * @param {String} confirmNewPassword - Confirmação da nova senha.
     * 
     * @returns {Promise<Boolean>}
     */

    async redefinePassword(password, newPassword, confirmNewPassword)
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            if(!password || !newPassword)
                throw { code: "auth/insert-password" }
            else if(!validatePassword(newPassword))
                throw { code: "auth/wrong-password" }
            else if(!confirmNewPassword)
                throw { code: "auth/insert-comfirm-password" }
            else if(newPassword !== confirmNewPassword)
                throw { code: "auth/passwords-not-match" }
            else
            {
                const credential = Firebase.auth.EmailAuthProvider.credential(this.email, password)

                if(await this._sessionData.reauthenticateWithCredential(credential))
                    return await this.updatePassword(newPassword, confirmNewPassword)
            }
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Atualizar a senha do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.updatePassword("123456789", "123456789") // true ou false
     * ```
     * 
     * @param {String} password        - Nova senha.
     * @param {String} confirmPassword - Confirmar nova senha.
     * 
     * @returns {Promise<Boolean>}
     */

    async updatePassword(password, confirmPassword)
    {
        try
        {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            if(!password)
                throw { code: "auth/insert-password" }
            else if(!confirmPassword)
                throw { code: "auth/insert-comfirm-password" }
            else if(password !== confirmPassword)
                throw { code: "auth/passwords-not-match" }
            else
            {
                await this._sessionData.updatePassword(password)
                await this.reload()

                return true
            }
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Deletar todos os dados e a conta do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.deleteAccount()
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    async deleteAccount()
    {
        try
        {
            await this.ref.delete()
            await this._sessionData.delete()

            return true
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }
}