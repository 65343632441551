import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import LoginLogoImage from '../../assets/images/LoginLogoImage.svg'
import BannerLogin from '../../assets/images/BannerLogin.svg'
import ArrowIcon from '../../assets/images/ArrowIcon.svg'

import './styles.css'

//components
import { RulesPassword } from '../../components/RulesPassword'
import { showLoadIndicator } from '../../components/LoadIndicator'
import Input from '../../components/Input'

//controllers
import Auth from '../../controllers/ControllerAuth'
import Alert from '../../controllers/ControllerAlert'

export const ChangePassword = () =>
{
    const [currentPassword, setCurrentPassword]               = useState(""),
          [newPassword, setNewPassword]                       = useState(""),
          [confirmNewPassword, setConfirmNewPassword]         = useState(""),
          navigate                                            = useNavigate()

    const changeCurrentPassword = e =>
    {
        e.preventDefault()

        showLoadIndicator(async () =>
        {
            if(await Auth.user.redefinePassword(currentPassword, newPassword, confirmNewPassword))
            {
                Alert.alert("Sucesso", "Senha alterada com sucesso.")
    
                navigate(-1)
    
                setCurrentPassword("")
                setNewPassword("")
                setConfirmNewPassword("")
            }
            // else
            //     Alert.alert("Erro", "Ocorreu um erro ao alterar sua senha.")
        }) 
    }

    return (
        <div className="a-page-Changepassword">
            <div className="linha-Changepassword">
                <div className="coluna-A-ChangePassword">
                    <img className="LoginPageImage" src={BannerLogin} alt="" />
                </div>
                <div className="coluna-B-ChangePassword">
                    <div className="ChangePassword-ArrowHeader">
                        <img className="ArrowIconImg" onClick={() => navigate(-1)} src={ArrowIcon} alt="" />
                        <p>Redefinir Senha</p>
                    </div>
                    <div id="ChangePasswordInitial">
                        <form className="FormChangePassword" onSubmit={changeCurrentPassword}>
                            <img className="LogoImageChangePassword" src={LoginLogoImage} alt="" />
                            <RulesPassword value={newPassword} />
                            <Input type="password" placeholder={"Senha atual"} value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} style={{ marginBottom: "24px", width: "100%" }} />
                            <Input type="password" placeholder={"Nova Senha"} value={newPassword} onChange={e => setNewPassword(e.target.value)} style={{ marginBottom: "24px", width: "100%" }} />
                            <Input type="password" placeholder={"Confirmar senha"} value={confirmNewPassword} onChange={e => setConfirmNewPassword(e.target.value)} style={{ marginBottom: "24px", width: "100%" }} />
                            
                            <button className="btn btn-enter">Solicitar</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="background-black"></div>
        </div>
    )
}

export default ChangePassword
