import UserBase from './ControllerUserBase'
import Alert from './ControllerAlert'

//utils
import { getNavigatorInfo } from '../utils/getNavigatorInfo'

export default class User extends UserBase
{
    /**
     * Retorna uma instância de um usuário e seus dados.
     * 
     * @example
     * ```js
     * await User.getByUserId("eliIuTbZy4YJu4eemWa0JraXOhi1") // new User()
     * ```
     * 
     * @param {String} userId - Id do usuário.
     * 
     * @returns {Promise<User>}
     */
    
    static async getByUserId(userId)
    {
        const user = new User(userId)
        
        await user.getOrSetData()

        return user
    }

    /**
     * Cria uma instância de usuário, podendo integarir com seus dados e salvando no firebase.
     * 
     * @example
     * ```js
     * new User("eliIuTbZy4YJu4eemWa0JraXOhi1") // new User()
     * ```
     * 
     * @param {String} userId - Id do usuário.
     * 
     * @returns {User} 
     */

    constructor(userId)
    {
        super(userId)

        this._data =
        {
            ...this._data,
            permission: 0
        }
    }

    /**
     * Retorna o nível de permissão do usuário.
     * 
     * @returns {Number}
     */

    get permission()
    {
        return this._data.permission ?? 0
    }

    /**
     * Deletar todos os dados e a conta do usuário.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.deleteAccount() // true ou false
     * ```
     * 
     * @returns {Promise<Boolean>}
     */

    async deleteAccount()
    {
        try
        {
            await super.deleteAccount()

            return true
        }
        catch(error)
        {
            Alert.error(error)

            return false
        }
    }

    /**
     * Atualiza no banco de dados o horário que o usuário teve interação com o ebook.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.updateUserInteraction()
     * ```
     */

    async updateUserInteraction() {
        try {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            const idToken = await this._sessionData.getIdToken()

            if(!idToken)
                throw { message: "Invalid Token" }

            let dataInteraction = localStorage.getItem("interactionData")

            if(dataInteraction)
                dataInteraction = JSON.parse(dataInteraction)

            if(!dataInteraction) {
                const navigatorInfo = getNavigatorInfo()

                dataInteraction = {
                    idToken,
                    deviceName: `${navigatorInfo?.brand} - ${navigatorInfo?.platform}`,
                    time: Date.now() + (2 * 60 * 1000)
                }

                const interactions = await this.ref.collection("interactions").where("idToken", "==", idToken).get()

                if(interactions.size <= 0) {
                    await this.ref.collection("interactions").add(dataInteraction)
                    localStorage.setItem("interactionData", JSON.stringify(dataInteraction))
                }
            } else if(dataInteraction?.time <= Date.now()) {
                dataInteraction.time = Date.now() + (2 * 60 * 1000)

                localStorage.setItem("interactionData", JSON.stringify(dataInteraction))

                const interactions = await this.ref.collection("interactions").where("idToken", "==", idToken).get()

                if(interactions.size > 0) {
                    const interaction = interactions.docs[0]
    
                    await interaction.ref.update(dataInteraction)
                }
            }
        } catch(error) {
            console.error(error)
        }
    }

    /**
     * Busca a última interação do usuário em um dispositivo diferente do atual.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.getLastInteraction() // {time: 321321312, deviceName: "Galaxy s22"} ou {}
     * ```
     * 
     * @returns {Promise<Object>}
     */

    async getLastInteraction() {
        try {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }
            
            const idToken = await this._sessionData.getIdToken()

            if(!idToken)
                throw { message: "Invalid Token" }

            const interactions = await this.ref.collection("interactions").orderBy("time", "desc").get()

            if(interactions.size > 0) {
                const interactionsFilterDocs = interactions.docs?.filter(interactionDoc => interactionDoc?.data()?.idToken !== idToken)

                if(interactionsFilterDocs.length) {
                    const interaction          = interactionsFilterDocs[0],
                            { time, deviceName } = interaction.data()

                    return { time, deviceName}
                }
            }

            return {}
        } catch(error) {
            console.error(error)

            return {}
        }
    }

    /**
     * Deleta a interação do usuário salva no banco de dados.
     * 
     * @example
     * ```js
     * const user = new User()
     * 
     * await user.deleteInteraction()
     * ```
     */

    async deleteInteraction() {
        try {
            if(!this._sessionData)
                throw { code: "auth/session-not-found" }

            const idToken = await this._sessionData.getIdToken()

            if(!idToken)
                throw { message: "Invalid Token" }

            const interactions = await this.ref.collection("interactions").where("idToken", "==", idToken).get()

            interactions.docs.map(interactionDoc => interactionDoc.ref.delete())
            localStorage.removeItem("interactionData")
        } catch(error) {
            console.error(error)
        }
    }
}